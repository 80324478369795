import React from "react"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function HobbitonMovieSetTourFromAuckland(props) {
  return (
    <>
      <SEO title="Hobbiton Movie Set Tour from Auckland - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/slideshow/hobbit-hole.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWAHA" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Hobbiton movie set tour from Auckland</h1>
                <div class="highlights">
                  <ul>
                    <li>Coach travel from Auckland</li>
                    <li>
                      See the world famous Hobbiton Movie Set in all its
                      intricate detail
                    </li>
                    <li>Be expertly guided as you stroll the lush farmland</li>
                    <li>
                      Top off your day with a complimentary drink at the Green
                      Dragon Inn
                    </li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      Travel in style on a coach tour from Auckland to Hobbiton
                      Movie Set - set on rolling farmland, this is the world
                      famous movie set where you'll see the intricately created
                      'Hobbit holes' the Green Dragon Inn, the Party Tree and so
                      much more cinematic magic!
                      <br />
                      <br />
                    </p>
                    <h4>Highlights:</h4>
                    <ul>
                      <li>Guided tour of the Hobbiton Movie Set</li>
                      <li>
                        Intricate 'Hobbit hole' houses, The Green Dragon Inn,
                        lush farmlard, the Party Tree and much more
                      </li>
                    </ul>
                    <p>
                      &nbsp;
                      <br />
                      Leaving Auckland, you'll experience beautiful countryside
                      in luxurious coach comfort. And when you arrive in
                      Matamata, enjoy lunch (own expense) at a local cafe before
                      joining the Hobbiton Movie Set bus that takes you to the
                      movie set itself!
                    </p>
                    <p>
                      You'll be expertly guided as you stroll around the set -
                      stand in the doorway of a Hobbit hole, see where the
                      hobbit Bilbo himself lived, and top off your day with a
                      complimentary beer, ale, cider or ginger beer at the Green
                      Dragon Inn. After returning to Matamata, rejoin the luxury
                      coach and return to Auckland, ready for your next
                      adventure.
                    </p>
                    <div class="clear"></div>

                    <div id="Summary" class="row">
                      <div id="right-col">
                        <h3>Reporting Time</h3>
                        <p>
                          15 minutes prior to departure - leaves from Sky City,
                          Auckland, at 9.00am and returns at 5.30pm.
                        </p>
                      </div>
                      <h3>Our Coaches</h3>
                      <p>Luxury, air conditioned, full size touring coaches</p>
                      <h3>Commentary</h3>
                      <p>A full commentary is provided in English.</p>
                    </div>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default HobbitonMovieSetTourFromAuckland
